import API_CONFIG from '../config';
import { Cookies } from 'react-cookie';

// Initialize a new instance of Cookies
const cookies = new Cookies();

const apiConnection = {
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    endpoints: API_CONFIG.paths,

    fetchProjects: async () => {
        const projectsURL = `${apiConnection.baseURL}${apiConnection.endpoints.getProjects}`;
        try {
            const response = await fetch(projectsURL);
            if (!response.ok) {
                throw new Error('Failed to fetch projects');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching projects:', error);
            throw error;
        }
    },

        fetchDataModels: async (project) => {
        const dataModelsURL = `${apiConnection.baseURL}${apiConnection.endpoints.getProjectDatamodels}${project}`;
        try {
            const response = await fetch(dataModelsURL, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`,  // Add Authorization header with JWT token
                    'Content-Type': 'application/json'  // Ensure content type
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data models for project ${project}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching data models for ${project}:`, error);
            throw error;
        }
    },

    fetchProjectDataModel: async (dataModelId, selectedProject) => {
        const projectDataModelURL = `${apiConnection.baseURL}${apiConnection.endpoints.getProjectDatamodel}${selectedProject}/${dataModelId}`;
        try {
            const response = await fetch(projectDataModelURL, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`,  // Add Authorization header with JWT token
                    'Content-Type': 'application/json'  // Ensure content type
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch project data model');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching project data model:', error);
            throw error;
        }
    },
    uploadClientDataModel: async (clientName, dataModel) => {
        const uploadURL = `${apiConnection.baseURL}${apiConnection.endpoints.addClientDataModel}${clientName}`;
        try {
            const response = await fetch(uploadURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(dataModel)
            });
            if (!response.ok) {
                throw new Error(`Failed to upload data model for client ${clientName}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error uploading data model for client ${clientName}:`, error);
            throw error;
        }
    },
    registerProductOwner: async (userData) => {
        const registerURL = `${apiConnection.baseURL}${apiConnection.endpoints.registerProductOwner}`;
        try {
            const response = await fetch(registerURL, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(userData)
            });
            if (!response.ok) {
                throw new Error('Failed to register product owner');
            }
            return await response.json();
        } catch (error) {
            console.error('Error registering product owner:', error);
            throw error;
        }
    },

    login: async (credentials) => {
        const loginURL = `${apiConnection.baseURL}${apiConnection.endpoints.login}`;
        try {
            const response = await fetch(loginURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials)
            });
            if (!response.ok) {
                throw new Error('Failed to login product owner');
            }
            return await response.json();
        } catch (error) {
            console.error('Error logging in product owner:', error);
            throw error;
        }
    },

    postProjectDataModel: async (projectId, dataModel) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.postProjectDataModel}${projectId}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(dataModel)
            });
            if (!response.ok) {
                return response.json().then(data => Promise.reject(data));
            }
            return await response.json();
        } catch (error) {
            console.error(`Error posting data model to project ${projectId}:`, error);
            throw error;
        }
    },

    addOrganization: async (clientData) => {
        const addClientURL = `${apiConnection.baseURL}${apiConnection.endpoints.addOrganization}`;
        try {
            const response = await fetch(addClientURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(clientData)
            });
            if (!response.ok) {
                throw new Error('Failed to add client');
            }
            return await response.json();
        } catch (error) {
            console.error('Error adding client:', error);
            throw error;
        }
    },

    getMyOrganizations: async () => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getMyOrganizations}`;
        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                if (response.status === 401){
                    cookies.remove('jwtToken');
                }
                throw new Error('Failed to get clients');
            }
            return await response.json();
        } catch (error) {
            console.error('Error getting clients:', error);
            throw error;
        }
    },

    getAllClients: async () => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getAllClients}`;
        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error('Failed to get all clients');
            }
            return await response.json();
        } catch (error) {
            console.error('Error getting all clients:', error);
            throw error;
        }
    },

    fetchClient: async (clientId) => {
        const getClientURL = `${apiConnection.baseURL}${apiConnection.endpoints.getClient}${clientId}`;
        try {
            const response = await fetch(getClientURL, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch client with ID: ${clientId}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching client with ID ${clientId}:`, error);
            throw error;
        }
    },

    fetchClientDataModels: async (clientId) => {
        const clientDataModelsURL = `${apiConnection.baseURL}${apiConnection.endpoints.getClientDataModels}${clientId}`;
        try {
            const response = await fetch(clientDataModelsURL, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data models for client ID: ${clientId}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching data models for client ID ${clientId}:`, error);
            throw error;
        }
    },

    addExistingClientToPO: async (clientId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.addExistingClient}`;
        const data = { client_id: clientId };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                throw new Error('Failed to add existing client');
            }
            return await response.json();
        } catch (error) {
            console.error('Error adding existing client:', error);
            throw error;
        }
    },

    addProject: async (projectData) => {
        const addProjectURL = `${apiConnection.baseURL}${apiConnection.endpoints.addProject}`;
        try {
            const response = await fetch(addProjectURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(projectData)
            });
            if (!response.ok) {
                throw new Error('Failed to add project');
            }
            return await response.json();
        } catch (error) {
            console.error('Error adding project:', error);
            throw error;
        }
    },

    addExistingProjectToPO: async (projectId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.addExistingProject}`;
        const projectData = { project_id: projectId };
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(projectData)
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to add existing project: ${errorData.error || 'Unknown error'}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error adding existing project:', error);
            throw error;
        }
    },

    fetchProjectById: async (project_id) => {
        const projectURL = `${apiConnection.baseURL}${apiConnection.endpoints.getProject}${project_id}`;
        try {
            const response = await fetch(projectURL, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch project with ID: ${project_id}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching project with ID ${project_id}:`, error);
            throw error;
        }
    },

    getMyProjects: async () => {
        const getMyProjectsURL = `${apiConnection.baseURL}${apiConnection.endpoints.getMyProjects}`;
        try {
            const response = await fetch(getMyProjectsURL, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error('Failed to get projects');
            }
            return await response.json();
        } catch (error) {
            console.error('Error getting projects:', error);
            throw error;
        }
    },

    updateImportAPI: async (clientid, modelid, importApiUrl) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.updateImportAPI}${clientid}/${modelid}`;
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify({import_api_url: importApiUrl})
            });
            if (!response.ok) {
                return response.json().then(data => Promise.reject(data));
            }
            return await response.json();
        } catch (error) {
            console.error(`Error updating import API for model ${modelid} and client ${clientid}:`, error);
            throw error;
        }
    },

    getAllProjects: async () => {
        const getAllProjectsURL = `${apiConnection.baseURL}${apiConnection.endpoints.getAllProjects}`;
        try {
            const response = await fetch(getAllProjectsURL, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error('Failed to get projects');
            }
            return await response.json();
        } catch (error) {
            console.error('Error getting projects:', error);
            throw error;
        }
    },

    runTestBatch: async (clientId, modelId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.run_test}${clientId}/${modelId}`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                }
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error running test batch for client ${clientId} and model ${modelId}:`, error);
            throw error;
        }
    },

    fetchProjectDataModels: async (projectId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getProjectDataModels}${projectId}`;
        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch data models for project ID: ${projectId}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching data models for project ID ${projectId}:`, error);
            throw error;
        }
    },

    hideProjectDataModel: async (projectId, dataModelId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.hideProjectDataModel}${projectId}/${dataModelId}`;
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to hide project data model: ${errorData.error || 'Unknown error'}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error hiding project data model:', error);
            throw error;
        }
    },

    hideClientDataModel: async (clientId, dataModelId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.hideClientDataModel}${clientId}/${dataModelId}`;
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to hide client data model: ${errorData.error || 'Unknown error'}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error hiding client data model:', error);
            throw error;
        }
    },
    addEmailServer: async (emailServerData) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.addEmailServer}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(emailServerData)
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to add email server: ${errorData.error || 'Unknown error'}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error adding email server:', error);
            throw error;
        }
    },
    getEmailServerConfig: async (clientId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getEmailServerConfig}${clientId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to get email server config: ${errorData.error || 'Unknown error'}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error getting email server config:', error);
            throw error;
        }
    },
    addMapping: async (modelId, clientId, subject) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.addMapping}`;
        const mappingData = {
            model_id: modelId,
            client_id: clientId,
            subject: subject,
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`,
                },
                body: JSON.stringify(mappingData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to add mapping: ${errorData.error || 'Unknown error'}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error adding mapping:', error);
            throw error;
        }
    },
    checkEmail: async (clientId) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.checkEmail}${clientId}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to check email: ${errorData.error || 'Unknown error'}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error checking email:', error);
            throw error;
        }
    },

    getClients: async () => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getClients}`;
        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error('Failed to get clients');
            }
            return await response.json();
        } catch (error) {
            console.error('Error getting clients:', error);
            throw error;
        }
    },
    registerUser: async (userData) => {
        const registerURL = `${apiConnection.baseURL}${apiConnection.endpoints.registerUser}`;
        try {
            const response = await fetch(registerURL, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(userData)
            });
            if (!response.ok) {
                throw new Error('Failed to register user');
            }
            return await response.json();
        } catch (error) {
            console.error('Error registering user:', error);
            throw error;
        }
    },
    deleteUser: async (userId) => {
        const deleteUserURL = `${apiConnection.baseURL}${apiConnection.endpoints.deleteUser}${userId}`;
        try {
            const response = await fetch(deleteUserURL, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to delete user');
            }
            return await response.json();
        } catch (error) {
            console.error('Error deleting user:', error);
            throw error;
        }
    },
    getMyDataModels: async () => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getMyDataModels}`;
        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data models');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching data models:', error);
            throw error;
        }
    },
    getMyOrganization: async () => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getMyOrg}`;
        try {
            const response = await fetch(url, {
                headers: { 'Authorization': `Bearer ${cookies.get('jwtToken')}` }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch organization');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching organization:', error);
            throw error;
        }
    },
        requestMagicLink: async (userData) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.requestMagicLink}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            console.error('Error requesting magic link:', error);
            throw error;
        }
    },

    loginWithToken: async (token) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.loginWithToken}${token}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            return await response.json();
        } catch (error) {
            console.error('Error logging in with token:', error);
            throw error;
        }
    },
    getNewApiKey: async ( name, clientId=null) => {
        const apiKeyURL = `${apiConnection.baseURL}${apiConnection.endpoints.getNewApiKey}`;
        try {
            const response = await fetch(apiKeyURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify({ client_id: clientId, name: name })
            });
            if (!response.ok) {
                throw new Error('Failed to generate API key');
            }
            return await response.json();
        } catch (error) {
            console.error('Error generating API key:', error);
            throw error;
        }
    },
    getMyAPIKeys: async (clientId = null) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getMyAPIKeys}`;
        const token = cookies.get('jwtToken');
        
        const data = clientId ? { client_id: clientId } : {};
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch API keys');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching API keys:', error);
            throw error;
        }
    },
    deactivateMyAPIKey: async (apiKeyHash, clientId = null) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.deactivateMyAPIKey}`;
        const requestBody = {
            hash: apiKeyHash,
        };

        if (clientId) {
            requestBody.client_id = clientId;  // Include clientId if provided
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify(requestBody)
            });
            if (!response.ok) {
                throw new Error('Failed to deactivate API key');
            }
            return await response.json();
        } catch (error) {
            console.error('Error deactivating API key:', error);
            throw error;
        }
    },
    getAPIKey: async (apiKeyHash, clientId=null) => {
        const url = `${apiConnection.baseURL}${apiConnection.endpoints.getAPIKey}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify({ hash: apiKeyHash, client_id: clientId })
            });
            if (!response.ok) {
                throw new Error('Failed to get API key');
            }
            return await response.json();
        } catch (error) {
            console.error('Error getting API key:', error);
            throw error;
        }
    },
    getClientDocumentation: async (clientId) => {
    const url = `${apiConnection.baseURL}${apiConnection.endpoints.getClientDocumentation}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${cookies.get('jwtToken')}`
                },
                body: JSON.stringify({ client_id: clientId })
            });
            if (!response.ok) {
                throw new Error('Failed to fetch client documentation');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching client documentation:', error);
            throw error;
        }
    },
};

export default apiConnection;
